.pageRegTeacher {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0 30px 0;
}

.formWrap {
    padding: 40px 50px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    border-radius: 40px;
}

.formWrapper {
    padding: 32px;
    width: 376px;
    border-radius: 30px;
}

.accept {
    /* composes: backgrondDefaultBlue from 'style.module.css';
    composes: backgrondDefaultBlueHover from 'style.module.css';
    composes: backgrondDefaultBlueActive from 'style.module.css'; */
    width: 100%;
}



/* Пробный стиль для отображения радиокнопок по полу */
/* .sexInput li{
    display: flex;
    gap: 8px;
    flex-direction: row;
} */
.sexInput {
    flex-direction: row;
    display: flex;
    gap: 8px;
    flex-direction: row !important;
}

.sexInput div:first-child {
    display: flex;
    gap: 10px;
    flex-direction: row
}


.radio {
    position: relative;
    /* flex-direction: row !important; */

}

.radio input[type=radio] {
    overflow: hidden;
    display: grid;
    place-content: center;
    -webkit-appearance: none;
    appearance: none;
    background-color: hsl(0, 0%, 82%);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 3em;
    height: 3em;
    border-radius: 30%;
    cursor: pointer;
    position: relative;
}

.radio input[type="radio"]:checked {
    background-color: #246ffb;
    transition: background-color 0.2s;
}

.radio input[type="radio"]:checked+label {
    color: #fff;

}

.radio input[type="radio"]:checked+span {
    color: #fff;
}

.radio label {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    margin-bottom: 0;
}

.radio span {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
}

/* ----------------------Конец пробнного стиля---------------------------- */


/* Политика обработки ПД */

.policy {
    padding: 18px 26px;
    border-radius: 20px;
    background: #E8F1FF;
    color: #163C85;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.policy a {
    text-decoration: underline;
    color: #397FE8;
}

.policy label {
    flex: 3;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.policy label>input[type='checkbox'] {
    display: none;
}

.policy label:hover {
    cursor: pointer;
}

.policy input {
    flex: 1;
}

.buttonSection {
    width: 100%;
    gap: 14px;
}


.regAction {
    gap: 10px;
}

.row {}

.column {}