.wrap {
    position: absolute;
    width: fit-content;
    padding: 15px;
    left: 50%;
    top: 50px;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
}

.wrap h4 {
    word-wrap: none;
}