.cardTitleSub {
    padding: 26px 30px 15px;
}

/* ------------------ */
.leftSection {
    max-width: 155px;
    width: 150px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    width: min-content;
}

.ButtonsSection {
    display: flex;
    width: min-content;
    min-width: 165px;
    gap: 10px;
}

.courseSwitch {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.courseSwitch input {
    margin: 0;
}


.courseBtn {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 180px;
    width: fit-content;
    max-height: 35px;
    padding: 8px 11px;
    height: 35px;

    border-radius: 11px;
    border: 0;

    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    transition: all 0.2s linear;
}

.publicate {
    background-color: #FFE5BD;
    color: #F99500;
    width: 100%;
    min-width: 125px;
    transition: all 0.3s linear;

}

.publicate.active {
    background-color: rgba(22, 208, 59, 0.15);
    color: #309D63;
    width: 125px;
}

.publicate.active:hover {
    color: #309D63;
    background-color: rgba(22, 208, 59, 0.35);
    width: 125px;
}

.publicate:hover {
    color: #F99500;
    background-color: rgba(251, 151, 3, 0.35);
}

.publicate:disabled {
    background-color: var(--base-disable-bgc);
    color: var(--base-disable-text);
    cursor: default
}

.publicate:disabled:hover {
    background-color: var(--base-disable-bgc);
    color: var(--base-disable-text);
    cursor: default
}

/* ------------------------------ */

.rightSection {
    max-width: 726px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
}

.courseInformation {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 14px;
}


p.value {
    /* min-width: 92px; */
  width: fit-content;
}

.DeletePopup {
    width: auto;
    gap: 20px;
}

.DeletePopup div.header button {
    display: none;
}

.DeletePopupHeader {
    display: flex;
    height: 44.029px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: #000;

    text-align: center;
    font-size: 27.518px;
    font-style: normal;
    font-weight: 600;
    margin: 0 20px;
}

.ErrorInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.InfoPopup {
    width: auto;
    gap: 20px !important;
}