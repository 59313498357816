.StudentCourseCard {
    position: relative;
    /* text-transform: uppercase;  */
    /* composes: defaultStyle from 'src/style.module.css'; */
    /* width: 100%; */
    padding: 0;

    display: flex;
    /* max-width: 1100px; */
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 20px;
    background: #FFF;
    border: 1px solid var(--defaulr-border-color);
    /* Общая тень */
    /* box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15); */
}

.CoursCardHead {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    cursor: pointer;
    width: 100%;
    padding: 14px 20px;
}

.headerButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;

}

.StudentCourseCardTitle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
}

.arrowIcon {
    font-size: 16px;
    scale: 1.5;
    line-height: 1;
    transition: all 0.3s linear;
    width: 30px;
    /* margin-right: 15px; */
}

.arrowIconcn.down {
    font-size: 16px;
    scale: 1.5;
    line-height: 1;
    transform: rotate(90deg);
    transition: all 0.3s linear;
    width: 30px;
    /* margin-right: 15px; */
}

.StudentCourseCardTitle p {
    color: #717171;
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    /* 22px */
}



.cancelBuy {
    display: flex;
    padding: 5px 15px;
    border-radius: 10px;
    background: rgba(244, 80, 80, 0.25);

    color: var(--color-red-default);

    font-size: 14px;
    font-weight: 700;
    line-height: 150%;

    white-space: nowrap;
}

.cancelBuy:hover {
    background-color: rgba(244, 80, 80, 0.35);
}

.ImgSection {
    width: min-content;
}

.content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 20px;
    padding: 20px;
    padding-top: 0;
}

.ImgWrap {
    width: 206px;
    height: 133px;
    border-radius: 20px;
    overflow: hidden;
}

.ImgWrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.PriceSpan {
    color: #909090;
}

.AttentionArea {
    margin-top: 12px;
    font-size: 11px;
    color: #32b2df;
}

.courseMenuBtn {
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    transition: all 0.3s linear;
    cursor: pointer;
}

.courseMenuBtn:hover {
    background-color: var(--defaulr-border-color);
}

/* -------------------------------------------- */

.CardPopupMenu {
    position: absolute;
    right: 0;
    width: 200px;
}

.ContentSection {
    align-items: self-start;
    gap: 10px;
    color: #000;

    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
}

/* --------------------------------------------- */

.BtnsSection {
    max-width: 200px;
    align-items: flex-start;
    gap: 10px;

}

.priceLabel {
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
}

.price {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 150%;
    /* 36px */
}

.OutBtn {
    display: flex;
    padding: 10px 20px;
    border-radius: 10px;
    background: rgba(36, 111, 251, 0.15);
    width: 100%;

    color: #246FFB;
    flex-wrap: nowrap;

    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    white-space: nowrap;
    justify-content: center;

}

.OutBtn.head {
    padding: 6px 20px;
}



.OutBtn:hover {
    background-color: rgba(107, 159, 255, 0.85);
    ;
}