.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.logo img {
    max-height: 100%
}

.horizontal {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
}

._center {
    align-items: center;
}

.vertical {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sendButton {
    padding: 14px 20px;
    border-radius: 14px;

    background: #E8F1FF;
    color: inherit;
    font-weight: inherit;
}

.sendButton:hover {
    background: #C6DAFF;
}

.sendButton:active {
    background: #A7C5FD;
}

.sendButton:disabled {
    background: #E4E4E4;
    color: #868686
}