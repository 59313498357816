.EducationWrap{
    /* composes: defaultStyle from 'src/style.module.css'; */
    display: flex;
    width: 100%;
    /* max-width: 1440px; */
    margin: 0 auto;

    padding: 0 20px 20px;
    /* padding: 70px 20px 20px; */
    gap: 20px;
}