.mainPopup {
    background-color: #f8f8f8;
    width: 440px;
    max-height: 700px;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.header>span {
    font-size: 20px;
    font-weight: 600;
}

.header>button {
    min-width: 42px;
    height: 42px;
    border-radius: 14px;
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #707070;
}

.header>button:hover {
    background-color: rgba(0, 0, 0, 0.25);
}