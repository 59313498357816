.simpleWhite, .cancel {
    padding: 7px 14px;
    color: #000000;
    border-radius: 10px;
    border: var(--default-style-border);
    transition: 0.3s linear;

}

.simpleWhite {
    background-color: #f8f8f8;
}

.simpleWhite:disabled {
    background-color: var(--defaulr-border-color);
}

.simpleWhite:disabled:hover{
    background-color: var(--defaulr-border-color);
}