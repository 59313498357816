.MySwitch {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.MySwitch label{
    min-width: 75px;
    cursor: pointer;
}

.MySwitch input {
    position: relative;
    appearance: none ;
    inline-size: 30px;
    block-size:  20px;
    padding: 2px;
    background-color: rgb(148, 148, 148);
    flex-shrink: 0;
    display: grid;
    align-items: center;
    grid: [track] 1fr / [track] 1fr;
    margin-right: 10px;
    transition: 1s;
    cursor: pointer;
    /* padding: 10px 15px; */
    border-radius: 10px;
}

.MySwitch>input::before {    
    position: absolute;
    content: "";
    grid-area: track;
    inline-size: 20px;
    block-size: 20px;
    transition: 1s;
}

.MySwitch  > input::before {
    content: "";
    position: absolute;  
    left: 2px;  
    right: unset;  
    grid-area: track;
    inline-size: 14px;
    block-size: 14px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    transition: 0.3s linear;
  }

  .MySwitch > input:checked:before {
    right: 2px;  
    left: unset;   
  }

  .MySwitch>input:checked{
    background-color: #77d529;
}

.MySwitch label {}