.topSectionLesson {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.LessonCard {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* composes: defaultStyle from 'src/style.module.css'; */
    border: 1px solid #d0d0d0;
    padding: 20px;
    box-shadow: none;
    background-color: rgba(36, 111, 251, 0.15);
    background: linear-gradient(0deg, rgba(204, 222, 255, 0.30) 0%, rgba(204, 222, 255, 0.30) 100%), #FFF;
    border: 0;
    border-radius: 0;
    width: 100%;
    /* border-radius: 20px; */

    /* margin-bottom: 20px; */
}

.EditLessonManageArea {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
}

.ChangeBtn {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    width: 140px;
    height: 30px;
    max-width: fit-content;
    line-height: 100%;
    padding: 16px;
    /* color: #717171;
    font-weight: 400; */
}

.editLessonBtn {
    background: #fff;
    border-radius: 50%;
    border: 1px solid #bbb;
    height: 40px;
    width: 40px;
    transition: all 0.3s linear;
}

.addLesoomModal {
    width: 600px;
}


.editLessonBtn.rebaseLesson {
    width: 45px;
    border-radius: 11px;
    background-color: #fff;
    border: 0;
    height: 30px;
}

.editLessonBtn.rebaseLesson:hover {
    background-color: rgb(174, 174, 255);
}

.editLessonBtn.delLesson:hover {
    background-color: #ec4c4c;
}

.DifficultInput {
    border-radius: 2px;
}

.DifficultInput h2 {
    font-size: 13px;
    font-weight: 500;
    margin-right: 10px;
}


.DifficultInput,
.DifficultInput div {
    display: flex;
    align-items: center;
    gap: 0;
    height: 8px;
    /* margin: 0 1px; */
    border-width: 0px 1px;
    border: 1px solid #fff;
    border-width: 0px 3px;
    border-radius: 2px;
    margin: 0;

}

.DifficultInput div:nth-child(2) {
    background-color: #00ff10;
    background: linear-gradient(90deg rgba(0, 255, 16, 1) 0%, rgba(234, 247, 12, 1) 100%);
}

.DifficultInput div:nth-child(3) {
    background-color: #eaf70c;
    background: linear-gradient(90deg, rgba(234, 247, 12, 1) 0%, rgba(255, 226, 0, 1) 10%);
}

.DifficultInput div:nth-child(4) {
    background-color: #ffa200;
    background: linear-gradient(90deg, rgb(255 206 0) 0%, rgb(255 120 0)100%);
}

.DifficultInput div:nth-child(5) {
    background-color: #fe5000;
    background: linear-gradient(90deg, rgba(255, 76, 0, 1) 0%, rgba(254, 0, 0, 1) 100%);
}

.DifficultInput div:nth-child(6) {
    background-color: #b700fe;
    background: linear-gradient(90deg, rgb(215 0 0) 0%, rgb(163 0 0) 100%);
}

.DifficultInput input {
    padding: 0;
    appearance: none;
    width: 30px;
    height: 8px;
    border-radius: 2px;
    position: relative;

}

.DifficultInput input::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    cursor: pointer;
    border-radius: 2px;
    transition: background-color 0.2s linear;
}

.DifficultInput input:hover::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    cursor: pointer;
    border-radius: 2px;
    outline: 2px solid #e9e6e6;
}

.DifficultInput input:checked::before {
    box-shadow: 0px 0px 4px #434343bf;
}

.DifficultInput label {
    appearance: none;
}

.durationlesson {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0;
    width: 300px;
}

.durationlesson p {
    width: max-content;
}

.durationlesson input {
    width: 150px;
}

.radiobutton input[type=radio] {
    display: none;
}

.radiobutton input[type=radio]:checked+label {
    background: #ffe0a6;
}

.radiobutton input[type=radio]:disabled+label {
    background: #efefef;
    color: #666;
}

.radiobutton label {
    display: inline-block;
    cursor: pointer;
    padding: 0px 15px;
    line-height: 34px;
    border: 1px solid #999;
    border-radius: 6px;
    user-select: none;
}

/* .radiobutton {
	display: inline-block;
	margin-right: 10px;
} */

.LessonLinks {
    display: flex;
    justify-content: space-between;
}

.LessonDeleteAlert {
    width: 300px;
    right: 30px;
}

.LessonDeleteAlert p {
    margin-bottom: 10px;
}

.LessonDeleteAlertBtns {
    padding-top: 10px;
    display: flex;
    gap: 10px
}


.PrevtopSectionLesson {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.prevContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.prevBtns {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-end;
}

.label {
    font-weight: 600;
}

.testsArea {
    width: max-content;
    background-color: #fff;
}