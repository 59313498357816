.MyLinkWrap {
    /* composes: backgrondDefaultBlue from 'style.module.css';
    composes: backgrondDefaultBlueHover from 'style.module.css';
    composes: backgrondDefaultBlueActive from 'style.module.css'; */
    /* width: 100%;
    height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* height: 100%; */
}