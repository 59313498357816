.content {
    width: 100%;
}

.content>section {
    width: auto;
}
.content>section {
    width: 100%;
}

.btnUpPage {
    width: 54px;
    height: 54px;
}

.optionListItems {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
    gap: 30px;
}

.actionPanel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px
}

.FiltersAndList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
}

.ListViewBtn {
    padding: 12px 24px;
    background-color: #22b936;
    height: 35px;
    border: none;
    border-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
}

.list.tiles {
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.ContentSection{
    max-width:100%;
    width: 100%;
    min-width: 600px;
}

.filterSection{
    max-width: 256px;
    min-width: 200px;
    width: 100%;
}