.pageLogin {
    padding: 30px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.logo img {
    max-height: 100%
}

.formWrapper {
    padding: 32px;
    width: 376px;
    border-radius: 30px;
}

.form {
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #163C85;
    font-weight: 600;
}

/* .switchAuthorize {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 30px;
} */

.entryArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.entryArea button {
    width: 100%;
}

.policy {
    padding: 18px 26px;
    border-radius: 20px;
    background: #E8F1FF;
    color: #163C85;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.policy a {
    text-decoration: underline;
    color: #397FE8;
}

.policy label {
    flex: 3;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.policy label>input[type='checkbox'] {
    display: none;
}

.policy label:hover {
    cursor: pointer;
}

.policy input {
    flex: 1;
}

.horizontal {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
}

._center {
    align-items: center;
}

.vertical {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sendButton {
    padding: 14px 20px;
    border-radius: 14px;

    background: #E8F1FF;
    color: inherit;
    font-weight: inherit;
}

.sendButton:hover {
    background: #C6DAFF;
}

.sendButton:active {
    background: #A7C5FD;
}

.sendButton:disabled {
    background: #E4E4E4;
    color: #868686
}