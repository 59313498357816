.durationFilerLabelRange{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    align-items: center;
    margin-bottom: 90px;
    flex-wrap: wrap;
}

.durationFilerLabelRange p{
    margin-bottom: 15px;
}


  .input-range {
    margin: 10px 0px;
  }

  .InputRangeNumbersinputs{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: center;
  }

  .disabled {
    display: none;
    color: #9b9b9b
  }

  .InputField{
    width: 90%;
    margin: 0 auto;
  }