.accept, .cancel {
    border: none;
    padding: 16px 24px;
    color: #fff;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s linear;
}

.accept:hover {
    background-color: #46D1B7;
}

.accept:active {
    background-color: #388B7C;
}

.accept {
    background-color: var(--color-green-default);
}

.accept:disabled {
    background-color: var(--defaulr-border-color);
}

.cancel {
    background-color: #d33e3e;
}