.publicModul {
    /* composes: defaultStyle from 'style.module.css'; */
    box-shadow: none;
    border: 1px solid #ddd;

    border-radius: 20px;
    width: 100%;
    background-color: #fff;
}

.LessonsListLabel {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    cursor: pointer;
}

.ModuleTitle {  
    font-size: 16px; 
    font-weight: 600;  
}

.publicModulLabel {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    font-weight: 700;

}

.arrowIcon {
    font-size: 16px;
    scale: 1.5;
    line-height: 1;
    transition: all 0.3s linear;
}

.arrowIconcn.down {
    font-size: 16px;
    scale: 1.5;
    line-height: 1;
    transform: rotate(90deg);
    transition: all 0.3s linear;
}

.LessonsList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px 20px;
}

.PublicLessonsList {
    display: flex;
    flex-direction: column;
    gap: 20px;

}