/* .StapperWrap{
  
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.StepperElement{
    background-color: #fff;
    width:80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid rgb(195, 195, 195);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s linear;
    outline: 0px solid #7ac5c0;
}

.active{
    background: #ffde2b;
    outline: 5px solid #7ac5c0;
}

.Stapper p{
    font-size: 26px;
    font-weight: 600;
}
 */

.Stapper * {
    /* border: 1px solid red; */
}

.Stapper {
    display: flex;
    min-height: 76px;
    width: 318px;
}

.StapperWrap {
    width: 100%;
    display: flex;
}

.StepperElement {
    width: 100%;
    display: flex;
    position: relative;

}

.StepperElement p {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 3.80042px 15px 0px rgba(0, 0, 0, 0.20);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    transition: 0.3s linear;
    z-index: 2;
}

.StepperElement:nth-child(1) p {
    top: 22px;
    left: 57px;
}

.StepperElement:nth-child(2) p {
    top: 47px;
    left: 48px;
}

.StepperElement:nth-child(3) p {
    top: 16px;
    right: 23px;
}

.StepperElement:nth-child(1)::before,
.StepperElement:nth-child(2)::before,
.StepperElement:nth-child(3)::before {
    content: '';
    position: absolute;
    display: block;    
    height: 4px;
    background-color: #ffffff;
    box-shadow: 0px 3.80042px 15px 0px rgba(0, 0, 0, 0.20);
    z-index: 1;

}

.StepperElement:nth-child(1)::before{
    left: 14px;
    bottom: 18px;
    top: 44px;
    rotate: 335deg;
    right: 42px;
}
.StepperElement:nth-child(2)::before{
    left: -29px;
    top: 44px;
    rotate: 16deg;
    right: 52px;

}
.StepperElement:nth-child(3)::before{
    left: -38px;
    bottom: 18px;
    top: 42px;
    rotate: 344deg;
    right: 42px;
}



.StepperElement.active p {
    background-color: #246FFB;
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.StepperElement.active:nth-child(1)::before,
.StepperElement.active:nth-child(2)::before,
.StepperElement.active:nth-child(3)::before
{
    background-color: #246FFB;
}

.StepperElement p {
    color: transparent
}