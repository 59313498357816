

.Navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 12px;
    /* padding: 0 8px; */
}

.Navigation::-webkit-scrollbar {
    width: 4px;
}

.Navigation::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 10px;
}

.Search {
    height: 36px;
    max-width: 438px;
    width: 100%;
    position: relative;
    border-radius: 12.186px;
    background: #EDEDED;
    display: flex;
    padding: 10px;
}

.Search input {
    align-items: center;
    color: #000;
    font-size: 10.684px;
    font-weight: 400;
}

.Search input::placeholder {
    color: #000;
}

.DropDownBtn {
    display: flex;
    padding: 11px 13px;
    justify-content: center;
    align-items: center;
    gap: 11px;
    border-radius: 14px;
    border: 0.955px solid #BEBEBE;
    width: fit-content;
    color: #000;
    height: min-content;
    cursor: pointer;
}

.DropDownBtn:hover {
    color: #ffffff;
    background: #A5A5A5;
}

.DropDownBtn svg {
    width: 18px;
}

.DropDownBtn svg:last-child {
    width: 8px;
    font-weight: 600;
}

.DropDownBtn p {
    font-size: 14px;
    font-weight: 600;
}

.DropDownList {
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background: #FFF;
    overflow: hidden;
    margin-top: 12px;
    z-index: 999999;
    box-shadow: 0px 0.3955px 17px 0px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    cursor: pointer;
}

.Profile {
    top: 44px;
    right: -15px;
}

.DropNavLink {
    display: flex;
    justify-content: flex-start;
    width: 220px;
    padding: 14px 20px;
    align-items: center;
    gap: 10px;
    color: #656565;
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), rgba(58, 119, 238, 0.00);
}

.DropNavLink:hover {
    background: rgba(209, 225, 254, 0.30);
}

.DropDownElement {
    padding: 0px;
    boreder-radius: 10px;
    cursor: pointer;
}

.DropDownList{
    padding: 0 !important;
    border-radius: 14px;
}

.accontMenu {
    position: relative;
    display: flex;
    align-items: center;
    gap: 14px;
    cursor: pointer;
}

.btnIcon {
    min-width: 26px;
    display: flex;
    /* height: 100%; */
    align-items: center;
    color: var(--alt-color);
    width: fit-content;
    height: auto;
}

.btnIcon svg {
    height: 24px;
    width: 100%;
}

.btnIcon.Favorite {
    color: var(--color-red-default);
    width: 26px;
}

.btnIcon.Favorite {
    fill: #fff;
    stroke: #ff2f2f;
    stroke-width: 50px;
}


@keyframes item_text {
    from {
        opacity: 1;
        visibility: visible;
    }

    70% {
        opacity: 0;
        visibility: hidden;
        display: none;
        transform: translateX(-20px);
    }

    to {
        opacity: 0;
        visibility: hidden;
        display: none;
        transform: translateX(-20px);
    }
}



.activeLink {
    background-color: #246ffb;
    color: #ffffff;
    transition: all 0.2s linear;
}

.icon {
    font-size: 1.6em;
    transition: all var(--anim-time) var(--anim-timing-func) var(--anim-fill-mode);
}