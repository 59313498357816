.radio 
{
    position: relative;
}
.radio input[type=radio] {
    overflow: hidden;
    display: grid;
    place-content: center;
    -webkit-appearance: none;
    appearance: none;
    background-color: hsl(0, 0%, 82%);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 3em;
    height: 3em;
    border-radius: 30%;
    cursor: pointer;
    position: relative;
}
.radio input[type="radio"]:checked {
    background-color: #246ffb;
    transition: background-color 0.2s;
}

.radio input[type="radio"]:checked + label {
    color: #fff;
    
}

.radio input[type="radio"]:checked+span {
    color: #fff;
}

.radio label {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    z-index: 10;   
    top: 0;
    margin-bottom: 0;   
}

.radio span {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
}

.sexInput li{
    display: flex;
    flex-direction: row ; 
    justify-content: flex-start;
    gap: 8px;
}
.sexInput div:first-child{
    display: flex;
    gap: 10px;
    flex-direction: row !important;
}

.sexInput {
    display: flex;
    flex-direction: row !important;;
    justify-content: flex-start;
    gap: 8px;
}