.ImgSection{
    max-width: 255px;
}

.ImgWrap {
   width: 100%;
    max-height: 151px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border-radius: 30px;
    overflow: hidden;
}

.ImgWrap img {
    min-width: 100%;
    min-height: 100%;
    flex-shrink: 0;
    object-fit: contain;

}


.progressWrap {
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    background: #F0F0F0;
    width: 100%;
    display: flex;
    overflow: hidden;
    padding: 20px;
    gap: 15px;
}

.ProggressBar {
    height: 9px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF
}

.ProggressBar .Fill {

  transition: all 0.5s linear;
    height: 9px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #246FFB;
}


.left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;



}

.right {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between

}

p.label {
    color: #000; 
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    white-space: nowrap ;
}