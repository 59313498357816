.homePage {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    height: 100vh;
}

.block {
    width: 100%;
    height: 100%;
    padding: 60px 0 60px 0;
    /* background: #DDF1FF; */
    background: #F8FBFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.buttonsWrapper {
    width: 100%;
    height: min-content;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    max-width: 1400px;
    justify-content: center;
}

.blockManyButton {
    width: 100%;
    height: min-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

.buttonBig {
    border-radius: 20px;
    height: 100%;
    padding: 44px;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0px 13px 30px 0px rgba(0, 0, 0, 0.10);
}

.buttonBig:hover {
    cursor: pointer;
    box-shadow: 0px 13px 34px 0px rgba(0, 0, 0, 0.30);
}

.textButtonBig {
    color: #035BFF;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: min-content;
    white-space: nowrap;
    align-items: flex-start;
}

.marginText {
    margin-bottom: 32px;
}

.iconButtonBig {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.recommendation {
    width: 100%;
    height: 100%;
    background: #FFF;
}

.topRec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}