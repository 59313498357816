.LessonPublic {
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: rgb(168, 168, 255); */
    transition: all 0.3s linear;

    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 10px;
    height: 54px;
}
.LessonPublic p {
    color: #fff;
}

.LessonPublic svg{
    font-size: 22px;
}

.unlock {
    color: #ffffff;
    background-color: #246FFB;
}

.unlock:hover {
    color: #fff;
    background-color: #69c6ff;
}

.LessonPublic svg{
    font-size: 22px;
}

.lock {
    background-color: #A3A3A3;
}

.goDemoLesson { 
    padding: 10px 20px;
    border-radius: 20px;
    background: #fff;
    color: #000
    ;
}