.filtersLabel {
  margin-bottom: 10px;
  font-weight: 700;
}

.filtersWrap {
  /* composes: defaultStyle from 'src/style.module.css';  */
  padding: 20px;
  flex-direction: column;
  min-width: 250px;
  max-width: 300px;
  border-radius: 30px;

  /* -------------------*/

  display: flex;
  max-width: 256px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 20px;
  background: #fff; 
}

.filtersWrap.specialSize {
  max-width: 300px;
  min-width: 200px;
  width: 100%;
}

.blue{
  background-color: #246FFB;
  transition: all 0.3s linear;
}
.blue:hover{
  /* background-color: #3f82fc; */
  background-color: #ef4747;  
}

.filterWrap{
  padding-bottom: 10px;
  border-bottom: 1px solid #818181;
  width: 100%;
}