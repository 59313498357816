.inputArea {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.inputArea label {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 10px;
}

.validationInputWrap { 
    display: flex;    
    width: 100%;
    align-items: center;
    gap: 10px;
    max-width: unset;
}
.ValidateError{
    width: 100%;
    color: #da0000;
    font-weight: 600;
}
