.Card {
    gap: 16px;
    align-items: flex-start;
}

.CourseLabel{
    font-size: 22px;
}

.CourseParams {
    gap: 10px;
    align-items: flex-start;
    justify-content: space-between;
}

.upSection {
    gap: 10px;
    align-items: stretch;
    width: 100%

}

.agreeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-red-default);
    padding: 14px 20px;
    border-radius: 11px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    max-height: 45px;
    width: 100%
}

.agreeButton:hover{
    background-color: #eb2d2d;
}


.subject {
    padding: 3px 8px;
    border-radius: 10px;
    background-color: #EBEBEB;
    line-height: 1;
    font-size: 12px;
    font-weight: 500;
}

.description{
    font-weight: 400;
    font-size: 20;
}

.description.full{
    font-weight: 400;
}

.buttons{
    width: 100%;
    justify-content: space-between;

}

.left{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    word-wrap: nowrap;
    flex: 1;
    font-weight: 500;
    cursor: pointer;
}
.right{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    word-wrap: nowrap;
    font-weight: 500;
    cursor: pointer;
}

.ConfirmButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    background-color: #E8F1FF;
    color: #163C85;
    font-weight: 600;
    border-radius: 13px;    
    overflow: hidden;
}
