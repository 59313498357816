.inputArea {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.inputArea label {
    font-size: 1em;
    font-weight: 600;
}

.checkBoxes li{
    list-style-type: none;
}

.ValidateError{
    width: 100%;
    color: #da0000;
    font-weight: 600;
}