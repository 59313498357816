.nameLesson p {
    margin-bottom: 15px;
}

.descriptionLesson p {
    margin-bottom: 15px;
}

.radioWrap {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.radioWrap div {
    gap: 5px;
}

.LessonOrder {
    display: flex;
    gap: 15px;
    align-items: center;
}

.addLessonSection {
    /* composes: defaultStyle from 'src/style.module.css'; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    /* max-width: 80%; */
    min-width: 100%;
    margin: 0 auto;
    /* margin-bottom: 20px; */
}

.addLessonBtn {
    padding: 16px 20px;
    width: 100%;
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
}

.nameLesson p {
    margin-bottom: 15px;
}

.descriptionLesson p {
    margin-bottom: 15px;
}

.durationlesson {
    flex-direction: row;
    align-items: stretch;
    gap: 10px;
    display: flex;
    width: 100%;
    max-width: unset;
}

/* .durationlesson input {
    max-width: 100px;
} */

.durationlesson .durationWrap {
    display: flex;
}

div.durationlesson p {
    min-width: max-content;
}

.radioWrap {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.radioWrap div {
    gap: 5px;
}

.Radio {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
}

.Radio h2 {
    font-size: 14px;
}

.Radio li {
    display: flex;
    gap: 10px;
}

.RadioField {
    position: relative;
    width: fit-content
}

.RadioField input[type=radio] {
    overflow: hidden;
    display: grid;
    place-content: center;
    appearance: none;
    background-color: hsl(0, 0%, 82%);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 130px;
    height: 40px;
    border-radius: 12px;
    cursor: pointer;
    position: relative;
}

.RadioField input[type="radio"]:checked {
    background-color: #246ffb;
    transition: background-color 0.2s;
}

.RadioField input[type="radio"]:checked+label {
    color: #fff;

}

.RadioField input[type="radio"]:checked+span {
    color: #fff;
}

.RadioField label {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    margin-bottom: 0;
}

.RadioField span {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
}

.AddLessonDurationInput input {
    width: 100px;
    /* max-width: 50%; */
}

.LessonOrder {
    display: flex;
    gap: 15px;
    align-items: center;
}

.OrderInput {
    width: 75px;
}

.OrderInput {
    width: 75px;
}

.addLessonTime {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: unset;
}

.durationWrap {
    display: flex;
    align-items: center;
    gap: 10px;
}