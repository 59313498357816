.accountMenuButton {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid #e7e7e7;
    border-radius: 14px;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px #00000025;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
}

.accountMenuButton:hover {
    box-shadow: 0px 0px 8px #00000040;
}

.icon {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3a3a3a;
}