.inputArea {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.inputArea label {
    font-size: 1em;
    font-weight: 600;
}
