.search {
    /* composes: defaultStyle from '@src/style.module.css'; */
    display: flex;
    align-items: center;
    padding: 14px;
    width: 100%;
}

.myinput {
    border-radius: 12px;
    background-color: #ffffff;
    padding: 5px 10px;
    width: 100%;
    height: 40px;
    border: 1px solid #dbdbdb;
    outline: none;
    font-weight: 500;
}

.myinput:focus {
    border-color:#246FFB;
    box-shadow:0 0 10px rgba(83, 143, 255, 0.466);
}