.errorField {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: min-content;
    background-color: #d53737;
    color: #fff;
    border-radius: 16px;
    font-weight: 400;
    padding: 16px 20px;
}

.confirm {
    background-color: var(--color-green-default);
}